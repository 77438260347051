<template>
  <div class="subscription">
    <Header />
    <HeaderBottom />
    <b-container class="subscription-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link to="/orders"
            ><b-icon class="mx-2" icon="chevron-left"></b-icon
            ><span>Kembali</span></b-link
          >
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="subscription-total-detail mb-4">
          <div class="review-title">
            <b-row class="justify-content-center my-2">
              <h4>Pembelian Reksa Dana</h4>
            </b-row>
          </div>
          <div class="order-detail">
            <b-row class="mt-4">
              <div class="col-lg-6 col-md-6 my-auto text-center text-lg-left">
                <p class="order-title">No.Order</p>
                <h2 class="order-number">{{ order.code }}</h2>
              </div>
              <div class="col-lg-6 col-md-6 my-auto text-center text-lg-right">
                <p class="date-title">Tanggal</p>
                <h3 class="date">
                  {{ moment(order.created_at).format(" DD MMM YYYY") }}
                  <!-- 7 Jun 2021, 11:39 WI -->
                </h3>
              </div>
            </b-row>
          </div>
          <hr />
          <div class="fund-name mt-4">
            <b-row>
              <div class="col-2 col-lg-1 text-left mb-2">
                <div v-if="order.product.image == null">
                  <img
                    :src="
                      'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                      order.product.fund_name
                    "
                  />
                </div>
                <div v-else>
                  <img
                    :src="`${imageCore}/images/products/${order.product.image}`"
                    alt="Fund Image"
                  />
                </div>
              </div>
              <div class="col-10 px-4 text-left">
                <h4>{{ order.product.fund_name }}</h4>
                <p>{{ order.product.investment_manager.im_name }}</p>
              </div>
            </b-row>
          </div>

          <div class="purchase-review">
            <b-row class="mt-4">
              <div class="col-6 my-auto text-left">
                <p class="variant">Pembelian</p>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="value">
                  {{ order.product.fund_ccy
                  }}{{ formatPrice(order.amount_nominal) }}
                </p>
              </div>
            </b-row>
          </div>
          <hr />
          <div class="subscription-cost">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <template v-if="order.fee_sales != null">
                  <template v-if="order.fee_type === 'PERCENT'">
                    <p class="variant">Biaya Pembelian (Percent)</p>
                  </template>
                  <template v-else-if="order.fee_type === 'NOMINAL'">
                    <p class="variant">Biaya Pembelian (Nominal)</p>
                  </template>
                  <template v-else>
                    <p class="variant">Biaya Pembelian (Unit)</p>
                  </template>
                </template>
                <template v-else>
                  <p class="variant">Biaya Pembelian</p>
                </template>
              </div>
              <div class="col-6 my-auto text-right">
                <template v-if="order.fee_sales != null">
                  <template v-if="order.fee_type === 'PERCENT'">
                    <p class="variant">{{ order.fee_sales }}%</p>
                  </template>
                  <template v-else-if="order.fee_type === 'NOMINAL'">
                    <p class="variant">
                      {{ order.product.fund_ccy
                      }}{{ formatPrice(order.fee_sales) }}
                    </p>
                  </template>
                  <template v-else>
                    <p class="variant">
                      {{ order.product.fund_ccy }}{{ order.fee_sales }}
                    </p>
                  </template>
                </template>
                <template v-else>
                  <p v-if="user.individual.sales_id != null" class="variant">
                    {{
                      order.product.fee_buy_text
                        ? order.product.fee_buy_text
                        : "-"
                    }}
                  </p>
                  <p v-else class="value">Gratis</p>
                </template>
              </div>
            </b-row>
          </div>
          <hr />
          <div class="total-purchase">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <p class="variant">Total</p>
              </div>
              <div class="col-6 my-auto text-right">
                <template v-if="order.fee_sales != null">
                  <p class="value">
                    {{ order.product.fund_ccy
                    }}{{ formatPrice(order.amount_nominal) }}
                  </p>
                </template>
                <template v-else>
                  <p class="value">
                    {{ order.product.fund_ccy
                    }}{{ formatPrice(order.amount_nominal) }}
                  </p>
                </template>
              </div>
            </b-row>
          </div>
        </b-card>

        <b-card class="subscription-total-detail mb-4">
          <div class="transaction-status ml-3">
            <b-row>
              <p>Status Transaksi</p>
            </b-row>
          </div>

          <template v-if="order.status == 'waiting sales'">
            <div class="stepper d-flex flex-column">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pembelian Diterima</p>
                  <p class="detail mt-n2"></p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="primary-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Menunggu Verifikasi Sales</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Pembayaran</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Verifikasi Pembayaran</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembayaran Diverifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Dalam Proses</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Berhasil</p>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="order.status == 'waiting itrade'">
            <div class="stepper d-flex flex-column">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon font-scale="1.5" class="text-muted" icon="check2-circle"></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pembelian Diterima</p>
                  <p class="detail mt-n2"></p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon font-scale="1.5" class="text-muted" icon="check2-circle"></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Menunggu Verifikasi Sales</p>
                    <p class="detail mt-n2"></p>
                  </div>
                </div>
              </template>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon class="primary-icon-color" font-scale="1.5" icon="circle"></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Menunggu Verifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon class="muted-icon-color" font-scale="1.5" icon="circle"></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Dalam Proses</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon class="muted-icon-color" font-scale="1.5" icon="circle"></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Berhasil</p>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="order.status == 'waiting confirm'">
            <div class="stepper d-flex flex-column">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon font-scale="1.5" class="text-muted" icon="check2-circle"></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pembelian Diterima</p>
                  <p class="detail mt-n2"></p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon font-scale="1.5" class="text-muted" icon="check2-circle"></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Menunggu Verifikasi Sales</p>
                    <p class="detail mt-n2"></p>
                  </div>
                </div>
              </template>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon class="primary-icon-color" font-scale="1.5" icon="circle"></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Menunggu Verifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon class="muted-icon-color" font-scale="1.5" icon="circle"></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Dalam Proses</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon class="muted-icon-color" font-scale="1.5" icon="circle"></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Berhasil</p>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="stepper d-flex flex-column">
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Permintaan Pembelian Diterima</p>
                  <p class="detail mt-n2"></p>
                </div>
              </div>
              <template v-if="user.individual.sales_id != null">
                <div class="d-flex mb-1">
                  <div class="d-flex flex-column pr-4 align-items-center">
                    <div>
                      <b-icon
                        font-scale="1.5"
                        class="muted-icon-color"
                        icon="check2-circle"
                      ></b-icon>
                    </div>
                    <div class="line h-100"></div>
                  </div>
                  <div class="success-transaction-status">
                    <p class="title text-muted">Menunggu Verifikasi Sales</p>
                    <p class="detail mt-n2"></p>
                  </div>
                </div>
              </template>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      font-scale="1.5"
                      class="muted-icon-color"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Pembayaran</p>
                  <p class="detail mt-n2"></p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Menunggu Verifikasi Pembayaran</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="check2-circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembayaran Diverifikasi</p>
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="primary-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                  <div class="line h-100"></div>
                </div>
                <div class="unsuccess-transaction-status">
                  <p class="title">Pembelian Dalam Proses</p>
                </div>
              </div>

              <div class="d-flex mb-1">
                <div class="d-flex flex-column pr-4 align-items-center">
                  <div>
                    <b-icon
                      class="muted-icon-color"
                      font-scale="1.5"
                      icon="circle"
                    ></b-icon>
                  </div>
                </div>
                <div class="success-transaction-status">
                  <p class="title text-muted">Pembelian Berhasil</p>
                </div>
              </div>
            </div>
          </template>

          <div class="estimation-complete">
            <b-row class="mt-2">
              <div class="col-6 my-auto text-left">
                <p class="variant">Estimasi Selesai</p>
              </div>
              <div class="col-6 my-auto text-right">
                <p class="value">
                  3 hari bursa <br />
                  <small>setelah tanggal transaksi</small>
                  <!-- 8 Jun 2021, 09:00 WIB -->
                </p>
              </div>
            </b-row>
          </div>
        </b-card>

        <!-- <b-card class="subscription-total-detail mb-4">
          <div class="payment-method mt-4">
            <h4>Metode Pembayaran</h4>
            <h3>Transfer Bank Manual</h3>
            <h2>
              {{
                product.rekening_product != null
                  ? product.rekening_product.bank.bank_name
                  : ""
              }}
            </h2>
          </div>

          <div class="upload-proof mt-4">
            <div class="bank-title">
              <b-row class="justify-content-md-start ml-1 mb-4">
                <div class="ekyc-form-upload">
                  <b-row>
                    <b-col>
                      <template>
                        <div class="dropbox">
                          <div class="proof-image text-center mt-3">
                            <img
                              :src="`${order.receipt_path}`"
                              height="128"
                              width="128"
                            />
                          </div>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </b-row>
            </div>
          </div>
        </b-card> -->

        <b-card class="subscription-total-detail mb-4">
          <div class="price-terms mt-4">
            <b-row>
              <div class="col-lg-12 col-md-6">
                <p>
                  <img
                    src="@/assets/img/icons/product/transaction-rules.svg"
                    alt="Ketentuan Transaksi"
                    class="mx-2"
                  />Ketentuan Transaksi
                </p>
              </div>
            </b-row>
          </div>
          <div class="transaction-time-before ml-3">
            <b-row class="mt-2">
              <p class="before">Transaksi sebelum 13:00 WIB</p>
            </b-row>
            <b-row>
              <p class="before-detail text-justify">
                Batas waktu konfirmasi Pembelian Reksa Dana paling lambat
                diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul 13:00
                WIB untuk diproses menggunakan harga NAV/Unit di hari bursa yang
                sama.
              </p>
            </b-row>
          </div>
          <div class="transaction-time-after ml-3">
            <b-row class="mt-2">
              <p class="after">Transaksi setelah 13:00 WIB</p>
            </b-row>
            <b-row>
              <p class="after-detail text-justify">
                Transaksi Pembelian yang diterima dan tercatat melebihi batas
                waktu pukul 13:00 WIB akan diproses dengan menggunakan harga
                NAV/Unit hari bursa berikutnya.
              </p>
            </b-row>
          </div>
          <div class="transaction-time-before ml-3">
            <b-row class="mt-2">
              <p class="before">Biaya dan Perpajakan</p>
            </b-row>
            <b-row>
              <p class="before-detail">
                Biaya dan Perpajakan akan timbul atas dasar ketentuan peraturan
                perundangan-undangan yang berlaku, sesuai dengan transaksi yang
                dimiliki.
              </p>
            </b-row>
          </div>
        </b-card>
        <b-button block to="/orders" class="mt-2 btn-tertiary py-2"
          >Lihat Aktivitas Dalam Proses</b-button
        >
        <!-- <b-button block href="#" class="mt-3 btn-outline-primary"
          >Batalkan Transaksi</b-button
        > -->
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottom from "@/components/partials/header/HeaderBottom.vue";
import Footer from "@/components/partials/footer/Footer.vue";

import instance from "@/utils/api";

export default {
  name: "WaitingForSubscriptionVerification",
  metaInfo: {
    title: "Detail Proses Pembelian | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottom,
    Footer,
  },
  data() {
    return {
      code: null,
      imageCore: process.env.VUE_APP_IMAGE_URL,
      order: {
        product: {
          fund_ccy: "",
        },
      },
      fund_code: null,
      product: {
        investment_manager: {
          im_name: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      app_url: "app_url",
      user: "auth/user",
      access_token: "auth/access_token",
    }),
    totalTrx: function () {
      const feeBuyTrx =
        (this.order.fee_sales / 100) * parseInt(this.order.amount_nominal);
      const total = parseInt(this.order.amount_nominal) + parseInt(feeBuyTrx);
      return total;
    },
    feeBuy: function () {
      const feeBuyTrx =
        (this.order.fee_sales / 100) * parseInt(this.order.amount_nominal);
      return feeBuyTrx;
    },
  },
  created() {
    this.code = this.$route.params.code;
    this.fund_code = this.$route.params.fund_code;
    this.fetchOrderGet();
  },
  methods: {
    async fetchOrderGet() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}subscription-get?code_buy=${this.code}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });

      if (!response.data.data) {
        return this.$router.push('/NOT-FOUND')
      }
      this.order = response.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}
.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-total-detail {
  padding: 20px;
  width: 600px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.review-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.purchase-review {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.order-detail {
  .order-title {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
  .order-number {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
  }

  .date-title {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #6b7280;
  }
  .date {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #6b7280;
  }
}

.subscription-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #38a169;
  }
}

.total-purchase {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .custodian-bank-name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #111827;
  }
}

.stepper {
  .line {
    width: 2px;
    background-color: lightgrey !important;
  }
  .lead {
    font-size: 1.1rem;
  }
}

.transaction-status p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.payment-method {
  h4 {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
}

.success-transaction-status {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.payment-accepted {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.unsuccess-transaction-status {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #5c0dab;
  }
}

.primary-icon-color {
  color: $primaryColor;
}

.success-transaction-status {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #6b7280;
  }
}

.estimation-complete {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.muted-icon-color {
  color: #d1d5db;
}

.dropbox {
  outline: 2px dashed grey; /* the dash box */
  background: #f3f4f6;
  color: dimgray;
  padding: 10px 10px;
  height: 200px; /* minimum height */
  width: 510px; /* minimum height */
  position: relative;
  border-radius: 5px;
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }
  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }
}

.btn-tertiary {
  width: 600px;
}

@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }
  .fund-name {
    h4 {
      font-size: 16px !important;
    }
  }
  p {
    font-size: 14px !important;
  }
}
</style>
